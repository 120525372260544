import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import $ from "jquery";
import * as baseUrl from '../commons/constants';
import { hasPermission } from '../commons/auth';
import '../cpDashboard.css';

const history = require('history').createBrowserHistory;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.activetab,
            userName: "",
            title: this.props.page_title,
            modelswitch: false,
            modelSwitchSite: false,
            modelDelete: false,
            orgArray: [],
            orgName: "",
            choosenTenant: "",
            modalsession: false,
            disableMenu: "disabled-link",
            switch_org_name: "",
            disable_switchacc: "disabled-link",
            logoff_Account: "none",
            UserTenant: localStorage.getItem("UserTenant"),
            user: {
                permissions: [

                ],
            },
            information: false,
            error: false,
            warning: false,
            settingsModal: false,
            notificationsModal: false,
            notificationsList: [],
            savemodal: false,
            count: 0
        };
        this.logOut = this.logOut.bind(this);
        this.showSidebar = this.showSidebar.bind(this);
        this.switchAccount = this.switchAccount.bind(this);
        this.SwitchAcctoggle = this.SwitchAcctoggle.bind(this);
        this.switchAccountOrgChange = this.switchAccountOrgChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.LogOffAccount = this.LogOffAccount.bind(this);
        this.SwitchOkAcctoggle = this.SwitchOkAcctoggle.bind(this);
        this.DeleteAcctoggle = this.DeleteAcctoggle.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.deleteAccountApi = this.deleteAccountApi.bind(this);
        this.closeOkPopup = this.closeOkPopup.bind(this);
        this.toggledeletesuccess = this.toggledeletesuccess.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
        this.togglesavesuccess = this.togglesavesuccess.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.getCustomization = this.getCustomization.bind(this);
    }

    getCustomization() {
        let tenant = localStorage.getItem('tenant');
        let url = baseUrl.LoginPath + "/api/admin/v1.0/account/tenant/customization?orgName=" + tenant;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        }).then(resp => {
            if (resp.status === 200) {
                return resp.json();
            }
        }).then(response => {
            if (response) {
                const siteManagementCustomization = response.find(el => el.operation === "Sitemanagement");
                if (siteManagementCustomization && siteManagementCustomization.active) {
                    this.setState({ ...this.state, hasSitemanagement: true });
                } else {
                    this.setState({ ...this.state, hasSitemanagement: false });
                }
            }
        })
    }

    changePassword() {
        history.push('/changePassword');
        window.location.reload();
    }

    togglesavesuccess() {
        this.setState({
            savemodal: !this.state.savemodal
        })
    }

    toggledeletesuccess = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleSettings() {
        this.setState({
            settingsModal: !this.state.settingsModal
        });
    }

    closeOkPopup() {
        history.push('/');
        window.location.reload();
    }

    deleteAccountApi() {
        var errors;
        this.setState({
            modelDelete: !this.state.modelDelete
        });

        var url = baseUrl.URLPath + "v1.0/user/account";
        fetch(url,
            {
                method: 'DELETE', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("Authorization"),
                }
            }
        ).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }
            else if (resp.status == 404) {
                this.setState({ errors: errors });
                return resp.json();
            }
            else if (resp.status == 500) {
                alert("internal server error")
            } else if (resp.status == 400) {
                alert("Bad request")
            } else if (resp.status == 200) {
                this.setState({
                    modal: !this.state.modal
                });
            }
            else { }
        })
            .catch(error => alert('Error:' + error));
        this.setState({ errors: errors });
    }

    SwitchOkAcctoggle() {
        if (this.state.identifier != "") {
            localStorage.setItem("switchOrgName", this.state.switch_org_name);
            this.state.logoff_Account = "block";
            var url = baseUrl.URLPath + "switchaccount/";

            fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("Authorization"),
                    "stenantid": this.state.identifier,
                }
            })
                .then((resp) => {
                    if (resp.status == 401) {

                    }
                    if (resp.status == 200) {
                        return resp.json();
                    }
                })
                .then((response) => {
                    if (response != undefined) {
                        let getViewDetails = localStorage.getItem("saveDetails")
                        localStorage.removeItem('SelectedSite');
                        if (response.status == "SUCCESS" && getViewDetails == "true") {
                            localStorage.setItem("saveDetails", false);
                            localStorage.setItem("Authorization", "Bearer " + response.token);
                            localStorage.setItem("tenant", this.state.identifier);
                            localStorage.setItem("choosen_tenant", this.state.identifier);
                            this.props.history.push('/wallboxList');
                            window.location.reload();
                        }
                        else if (response.status == "SUCCESS") {
                            localStorage.setItem("saveDetails", false);
                            localStorage.setItem("Authorization", "Bearer " + response.token);
                            localStorage.setItem("tenant", this.state.identifier);
                            localStorage.setItem("choosen_tenant", this.state.identifier);
                            window.location.reload();
                        }
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
            this.setState({
                modelswitch: !this.state.modelswitch
            });
        }
    }

    switchAccountOrgChange(event) {
        this.state.identifier = event.target.value;
        var index = event.nativeEvent.target.selectedIndex;
        var switch_org_name = event.nativeEvent.target[index].text
        this.state.switch_org_name = switch_org_name;
    }

    logoff=()=>{
        
    }
    LogOffAccount(event) {
        this.state.identifier = event.target.value;
            var url = baseUrl.URLPath + "loggedoff/";
            fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("Authorization"),
                }
            })
                .then((resp) => {
                    if (resp.status == 401) {

                    }
                    if (resp.status == 200) {
                        return resp.json();
                    }
                })
                .then((response) => {
                    if (response != undefined) {
                        debugger;
                        if (response.status == "SUCCESS") {
                            localStorage.setItem("Authorization", "Bearer " + response.token);
                            localStorage.setItem("choosen_tenant", "none");
                            localStorage.setItem("tenant", localStorage.getItem("UserTenant"));
                            localStorage.setItem("switchOrgName", "");
                            this.props.history.push('/wallboxList')
                            window.location.reload();
                        }
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })

    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }

    SwitchAcctoggle() {
        this.setState({
            modelswitch: !this.state.modelswitch
        });
        if (this.state.identifier != "") {
            this.state.identifier = localStorage.getItem("tenant");
        }
    }

    switchAccount() {
        this.SwitchAcctoggle()
    }

    DeleteAcctoggle() {
        this.setState({
            modelDelete: !this.state.modelDelete
        });
    }

    deleteAccount() {
        this.DeleteAcctoggle()
    }

    logOut() {
        localStorage.clear();
        history.push('/');
        window.location.reload();
    }

    componentWillMount() {
        var user = localStorage.getItem('user');
        this.setState({ userName: user });
    }

    showSidebar() {
        var js = document.getElementsByClassName('sidebar-main-div');
        if ($(js).hasClass("scroll_nav")) {
            $(js).removeClass("scroll_nav");
            $('nav').removeClass("scroll_nav");
            $('#navbar').removeClass("nav_p");
        } else {
            $(js).addClass("scroll_nav");
            $('#navbar').addClass("nav_p");
        }
    }

    componentDidMount() {
        this.state.user.permissions = localStorage.getItem("roleAccess");
        if (hasPermission(this.state.user, ['SwitchAccount'])) {
            this.state.disable_switchacc = "";
        }
        this.state.choosenTenant = localStorage.getItem("choosen_tenant");
        if (this.state.choosenTenant == "none" && hasPermission(this.state.user, ['SwitchAccount'])) {
            this.setState({
                disableMenu: ""
            })
        }

        var url = baseUrl.URLPath + "admin/v1.0/account/all";
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({ orgArray: response.organisationDTOS })
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            });
        this.getCustomization();
    }

    showSidebar() {
        var js = document.getElementsByClassName('sidebar-main-div');
        if ($(js).hasClass("scroll_nav")) {
            $(js).removeClass("scroll_nav");
        } else {
            $(js).addClass("scroll_nav");
        }
    }

    render() {
        if (localStorage.getItem("roleAccess") == "Inventory_R,CSR_R" && this.state.orgArray.length == 0) {
            this.state.orgName = localStorage.getItem("tenant");
        }

        let switch_org = localStorage.getItem("switchOrgName");

        let org_full_name = "";
        const organisation = this.state.orgArray.map((orgArray, index) => {
            var setOrg = false;
            var HideOrg = "";
            if (switch_org != "" && switch_org != null && (switch_org.toLowerCase() == orgArray.company.toLowerCase())) {
                switch_org = orgArray.company;
                switch_org = switch_org.split(" ");
                switch_org = switch_org[0];
                this.state.logoff_Account = "block";
                setOrg = true
                this.state.identifier = orgArray.identifier;
                org_full_name = orgArray.company;
            }
            if (this.state.UserTenant.toLowerCase() == orgArray.identifier.toLowerCase()) {
                HideOrg = "none";
                org_full_name = orgArray.company;
                this.state.orgName = orgArray.company;
            }
            return (
                <option value={orgArray.identifier} selected={orgArray.identifier == this.state.UserTenant ? false : setOrg} style={{ "display": HideOrg }} > {orgArray.company} </option>
            )
        })

        return (
            <>
                <header>
                    {/* <!-- Navbar--> */}
                    <nav className="navbar fixed-top navbar-expand-md navbar-light white double-nav scrolling-navbar custom-navbar" >
                        {/* <!-- SideNav slide-out button --> */}
                        <div className="breadcrumb-dn slide-arrow" onClick={this.showSidebar}>
                            <p><i className="fas fa-bars" id="toggle_arrow" style={{ color: "#00529e" }}> </i></p>
                        </div>
                        <div className="text-left w-15 pl-4">
                            {
                                localStorage.getItem("role") != "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE" ?
                                    <a href="/wallboxList" className="pl-0"><img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "120px" }} alt="" /></a>
                                    :
                                    <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "120px" }} alt="" />
                            }

                        </div>
                        {/* <!-- Navbar links--> */}
                        <div className="mr-auto pl-2 input-div">
                            <div className="input-group input-search ">
                                <i className="fas fa-search fa-sm"></i>
                                <input type="text" className="border-0 small" placeholder="Search for something..." aria-label="Search" aria-describedby="basic-addon2" />
                            </div>
                        </div>
                        {/* <!--Navigation icons--> */}
                        <span id="dpl-navbar-right-buttons" >
                            {/* <!--Navigation icons--> */}
                            <ul className="nav navbar-nav nav-flex-icons ml-auto">
                                <li className="nav-item orgnization_name pr-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 0 }}>
                                    <span>Organization : {(localStorage.getItem("switchOrgName") == "" || localStorage.getItem("switchOrgName") == null) ? this.state.orgName : localStorage.getItem("switchOrgName")}</span>
                                </li>
                                {/* <!-- Tools --> */}
                                <li id="navbar-static-tools" className="nav-item dropdown">
                                    <a className="nav-link" id="navbar-tools" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <label className="profile-pic" >
                                            <img src={process.env.PUBLIC_URL + "/assets/img/user.png"} alt="" />
                                        </label>

                                        <span className="d-none d-xl-inline-block ml-1 profile-pic-span">{this.state.userName}</span>
                                        <i class="fas fa-chevron-down profile-pic-arrow"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right drop_menu  pt-1 pb-1" aria-labelledby="userDropdown">

                                        {hasPermission(this.state.user, ['SwitchAccount']) && <>
                                            <a className={"dropdown-item cursor-p " + this.state.disable_switchacc} onClick={this.switchAccount} >
                                                <span className="fw-500">Switch Account</span>
                                            </a>
                                        </>
                                        }
                                        {hasPermission(this.state.user, ['SwitchAccount']) && <>
                                            <a className={"dropdown-item cursor-p"} style={{ "display": this.state.logoff_Account }} id="logoff_account" 
                                            onClick={
                                                (e)=>{this.LogOffAccount(e); 
                                                    setTimeout(() => {
                                                        window.location.reload()
                                                    }, 1000);
                                                }}
                                             >
                                                <span className="fw-500"> Log Off Organisation: <b>{switch_org}</b></span>
                                            </a>
                                        </>
                                        }
                                        <a className="dropdown-item cursor-p" href="/" onClick={this.logOut}>
                                            <span className="fw-500"> Sign Out </span>
                                        </a>
                                    </div>
                                </li>
                                {/* <!-- Login / register --> */}
                            </ul>
                        </span>
                    </nav>
                    {/* <!-- /.Navbar--> */}
                </header>
                <MDBModal isOpen={this.state.modelswitch} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
                    <MDBModalHeader toggle={this.SwitchAcctoggle}>Switch Account</MDBModalHeader>
                    <MDBModalBody>
                        <div class="row">
                            <label className="switch_acc" > Select Organization </label>
                            <div class="col-md-7">
                                <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.switchAccountOrgChange.bind(this)}>
                                    <option value=""> Select Organization </option>
                                    {organisation}
                                </select>
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" onClick={this.SwitchAcctoggle} className="btn_cancel">Cancel <i class="fas fa-times ml-2"></i></button>
                        <button type="button" data-test="button" onClick={this.SwitchOkAcctoggle} className="btn_primary">OK</button>
                    </MDBModalFooter>
                </MDBModal>
                {/* popup for delete account */}
                <MDBModal isOpen={this.state.modelDelete} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
                    <MDBModalHeader toggle={this.DeleteAcctoggle}>Confirmation</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete?<br></br><br></br>
                        Note:The user has two weeks, to activate his account again. After the two weeks, the account gets deleted permanently.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" onClick={this.DeleteAcctoggle} className="btn_cancel">Cancel <i class="fas fa-times ml-2"></i></button>
                        <button type="button" data-test="button" onClick={this.deleteAccountApi} className="btn_primary">YES</button>
                    </MDBModalFooter>
                </MDBModal>
                {/* popup for session expire */}
                <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                    <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
                    <MDBModalBody>
                        Please login again.
                    </MDBModalBody>
                    <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
                        <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">OK</button>
                    </MDBModalFooter>
                </MDBModal>
                {/* popup for sucessfull delete */}
                <MDBModal isOpen={this.state.modal} toggle={this.toggledeletesuccess} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggledeletesuccess}>Success</MDBModalHeader>
                    <MDBModalBody>
                        Account deleted successfully.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" onClick={this.closeOkPopup} className="btn_primary">OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}

export default Header;
