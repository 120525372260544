import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBBtn } from "mdbreact";
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './commons/constants';
import * as roles from './commons/roles';
import * as GeneralUser from './commons/GeneralUser';
import * as PlatformUser from './commons/PlatformUser';
import * as TenantUser from './commons/TenantUser';
import { Link } from 'react-router-dom';  
import { Alert } from 'reactstrap';
import $ from "jquery";
import { hasPermission } from './commons/auth';
import Pagination from "react-js-pagination";
import { Typeahead } from 'react-bootstrap-typeahead';
import './cpDashboard.css';

class userManagementController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveSelectedCpid: "",
      fields: {
        emailId: ""
      },
      options: [],
      multiple: false,
      errors: {
        selectChargepoint: "",
        notexists: ""
      },
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      activePage1: 1,
      pageSize1: 10,
      noOfRecords1: '',
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      actiondisplay: "",
      activeArray: [],
      checked: false,
      activeDeactiveArray: '',
      list: [],
      page_title: "Users",
      sucess: false,
      userArray: [],
      model1: "false",
      model2: "false",
      id: "",
      dataArray: [],
      org_name: '',
      modalsession: false,
      user: {
        permissions: [],
      },
      userid: '',
      openFreeWallboxes: false,
      selectedWallboxes: [],
      availableWallboxes:[],
      deletedWallboxes:[],
      updateWallboxes: false
    }
    this.addNew = this.addNew.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.getUserslist = this.getUserslist.bind(this)
    this.activeUsers = this.activeUsers.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.tagOk2 = this.tagOk2.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.closeOkPopup = this.closeOkPopup.bind(this)
    this.toggle = this.toggle.bind(this)
    this.togglesession = this.togglesession.bind(this)
    this.sessiontagOk = this.sessiontagOk.bind(this)
    this.getNonWebastoUsers = this.getNonWebastoUsers.bind(this);
    this.goLoginHistory = this.goLoginHistory.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onChangeOrg = this.onChangeOrg.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.toggleFreeWallboxes = this.toggleFreeWallboxes.bind(this);
    this.removeSelectedItem = this.removeSelectedItem.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.updateSelectedWallboxes = this.updateSelectedWallboxes.bind(this);
    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.getAllWallboxes = this.getAllWallboxes.bind(this);
    this.getAllSelectedWallboxes = this.getAllSelectedWallboxes.bind(this);
    this.handlePageChange1 = this.handlePageChange1.bind(this);
    this.showEntriesChange1 = this.showEntriesChange1.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.getInventorybasedonchargepoint = this.getInventorybasedonchargepoint.bind(this);
    this.chargepointIDChange = this.chargepointIDChange.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
  }
  ClearChargepoint(ref, event) {
    this.state.selectFindStatus = false;
    let errors = {};
    errors["chargePointNotExists"] = "";
    this.setState({
      errors: errors
    });
    this.state.saveSelectedCpid = "";
    this.state.pageSize = 10;
    this.state.activePage = 1;
    this.getAllWallboxes(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    this.setState({
      errors: errors
    });
    ref.current.clear();
  }
  // api to get chargepointId based on search filter
  chargepointIDChange(cpid) {
    this.state.selectFindStatus = true;

    this.state.saveSelectedCpid = cpid
    this.setState({ saveSelectedCpid: cpid })
    if (cpid.length == 0) {
      this.getAllWallboxes(this.state.pageSize, this.state.activePage)
    } else {
      let errors = {};
      errors["chargePointNotExists"] = "";
      errors["selectChargepointId"] = "";
      this.setState({
        errors: errors
      });
      this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage, cpid);
    }

  }
  FindCpName(ref, event) {
    this.state.saveSelectedCpid = ref.current.inputNode.value;
    var errors = {};
    if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
      errors["selectChargepointId"] = "Please select the serial number.";
      this.setState({
        errors: errors
      });
      this.state.pageSize = 10;
      this.state.activePage = 1;
      this.getAllWallboxes(this.state.pageSize, this.state.activePage)
    } else {
      errors["selectChargepointId"] = "";
      this.state.selectFindStatus = true;
      this.state.pageSize = 10;
      this.state.activePage = 1;
      this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage);
    }

    var serial_no = event.target.value;

    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();
    }
  }
  getInventorybasedonchargepoint(pageSize, pageNo, saveSelectedCpid) {
    var errors = {};
    if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
      errors["selectChargepointId"] = "Please select the serial number.";
      this.setState({
        errors: errors
      });
    } else {
      if (this.state.selectFindStatus == true) {
        var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
      }
      else {
        var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
      }

      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          } else if (resp.status == 404) {
            errors["chargePointNotExists"] = "Serial Number not exists.";
            this.setState({
              errors: errors
            });
          } else if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            debugger
            if (response.count.count == 0) {
              errors["chargePointNotExists"] = "Serial Number not exists.";
              this.setState({
                errors: errors
              });
              $("#nochargeexists").show();
            } else {
              this.state.activeArray = "";
              $("#nochargeexists").hide();
              let wallboxes = []
              let x = response.cpList?.map((list,index)=>{
                wallboxes.push(list.serial_number)
              })
              this.setState({
                availableWallboxes: wallboxes, 
                noOfRecords1: response.count.count
              });
            }
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }
  getSearchInventoryList(cpid) {
    debugger
    var url = baseUrl.WebPath + "chargers/search/partial?cpid=" + cpid;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else if (resp.status == 200) {
          return resp.json();
        } else if (resp.status == 500) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.status == 500) {
          this.setState({
            options: [],
            intial_data: []
          });
        } else if (response != undefined) {
          this.setState({
            options: response,
            intial_data: response
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }
  keyPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ emailId: e.target.value });
      this.getUserslist(this.state.id, this.state.pageSize, 1);
      if (this.state.fields.emailId == "") {
        this.state.errors["selectChargepoint"] = "Please enter email id"
        $("#notexists").text(" ");
      } else {
        this.state.errors["selectChargepoint"] = " "
        this.getUserslist(this.state.id, this.state.pageSize, 1, "enter");
      }
    }

    if (key == "Backspace" || key == "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {
        this.state.fields.emailId = sno;
        this.getUserslist(this.state.id, this.state.pageSize, 1);
      }
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9.@-]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.pageSize = entries;
    this.state.activePage = 1;
    this.getUserslist(this.state.id, entries, 1);
  }
  showEntriesChange1() {
    var entries = document.getElementById("showEntries1").value;
    this.state.pageSize1 = entries;
    this.state.activePage1 = 1;
    this.getAllWallboxes(entries, 1);
  }
  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getUserslist(this.state.id, this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }
  handlePageChange1(pageNumber){
    if (pageNumber != this.state.activePage1) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage1: pageNumber }, ()=>{
          this.getAllWallboxes(this.state.pageSize1, pageNumber);
        });
      } else {
        this.setState({ activePage1: pageNumber });
      }
    }
  }
  handleClick() {
    let fields = this.state.fields;
    fields.emailId = '';
    this.state.errors["selectChargepoint"] = " "
    $("#notexists").text(" ");
    this.setState({
      fields
    });
    this.state.pageSize = 10;
    this.state.activePage = 1;
    document.getElementById("showEntries").value = 10;
    this.getUserslist(this.state.id, this.state.pageSize, this.state.activePage);
  }
  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.emailid = document.getElementById('emailId')
    this.state.errors["selectChargepoint"] = "Please enter email id"
    this.setState({ fields });
    this.state.errors["selectChargepoint"] = ""
    $("#notexists").text(" ");
  }
  //change function for user  filter
  onChangeEmail() {
    var email = document.getElementById("emailId").value;
    this.setState({ emailId: email }, () => {
      this.getUserslist(this.state.id, this.state.pageSize, 1);
    });
    if (this.state.fields.emailId == "") {
      this.state.errors["selectChargepoint"] = "Please enter email id"
      $("#notexists").text(" ");
    } else {
      this.state.errors["selectChargepoint"] = " "
      this.getUserslist(this.state.id, this.state.pageSize, 1, "click");
    }
  }
  onChangeOrg() {
    this.getUserslist(this.state.id, this.state.pageSize, 1);
  }
  async goLoginHistory() {
    this.props.history.push('/userLoginHistory');
  }
  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  sessiontagOk() {
    this.props.history.push('/');
    window.location.reload();
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleConfirmation(event) {
    this.setState({
      modal1: !this.state.modal1
    });
    if (this.state.list[0] != "" && this.state.list[0] != undefined) {
      this.setState({
        delete_msg: "Are you sure you want to delete?",
        deleteHeader: "Confirmation",
        deleteButton: "",
        deleteText: "Close",
        deletecolor: "danger"
      })
    } else {
      this.setState({
        delete_msg: "Please select the User.",
        deleteHeader: "Warning",
        deleteButton: "d-none",
        deleteText: "Ok",
        deletecolor: "primary"
      })
    }
  }
  closeOkPopup() {
    this.setState({
      modal: !this.state.modal
    });
    window.location.reload();
  }
  tagOk1() {
    window.location.reload();
  }
  tagOk2() {
    window.location.reload();
  }
  toggle1() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }
  closeModel() {
    window.location.reload();
  }
  addNew() {
    this.props.history.push('/addUserManagement');
  }
  // calling function when click on checkbox
  handleChange(id, identifier, event) {

    let arr = [];
    var newlist = [];
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue == true) {
      if (this.state.list[0] != "" && this.state.list[0] != undefined) {
        document.getElementById(this.state.list[0]).checked = false;
        document.getElementById(id).checked = true;
      }
      this.state.list[0] = id;
      this.setState({
        activeArray: identifier
      });
    }
    if (checkedValue == false) {
      this.state.list[0] = "";
      document.getElementById(id).checked = false;
      this.setState({
        activeArray: ""
      });
    }
    this.state.activeDeactiveArray = identifier;
  }
  getUserslist(id1, pageSize, pageNo, action) {
    var role = localStorage.getItem('role');

    if (id !== "") {
      id = this.state.id;
    } else {
      id = id1;
    }

    this.setState({ id: id })
    var id = document.getElementById("organizationid").value;
    var el = document.getElementById('organizationid')
    var selectedText = el.options[el.selectedIndex].text
    this.setState({
      org_name: selectedText
    });
    var url = "";
    url = PlatformUser.GET_USERS +"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + this.state.fields.emailId + "&id=" + id;
    
    document.getElementById("loader_image_div").style.display = "block";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
        }, 100);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          if (response.count.count == 0) {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              if (action == "click" || action == "enter") {
                $("#notexists").text("Email id not exists.");
              }
              this.setState({
                userArray: [],
                noOfRecords: 0
              });
            }, 100);
          } else {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              this.setState({
                userArray: response.userList,
                noOfRecords: response.count.count
              });
              if (action == "click" || action == "enter") {
                $("#notexists").text("");
              }
            }, 100);
          }
        }
      })
      .catch((error) => {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          console.log(error, "catch the loop");
        }, 100);
      })
  }
  activeUsers(status) {
    var role = localStorage.getItem('role');

    if (this.state.activeArray != "" && this.state.activeArray.length != "0") {
      if (status == true) {
        var url = "";
          url = PlatformUser.ACTIVATE_USER + this.state.activeArray;
      } else {
        var url = "";
          url = PlatformUser.DEACTIVATE_USER + this.state.activeArray;
        
      }
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          return resp.json();
        })
        .then((response) => {
          if (status == 0) {
            this.toggle1()
          } else {
            this.toggle2()
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else if (this.state.activeArray == "" && this.state.activeArray.length == "0") {
      this.toggleConfirmation();
    }
  }
  componentDidMount() {
    this.state.user.permissions = localStorage.getItem("roleAccess");
    var orgname = localStorage.getItem("tenant");
    this.setState({
      org_name: orgname
    })
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response.organisationDTOS })
          var user_data = [];
          user_data = response.organisationDTOS;
          var first_org = "";
          for (var i = 0; i <= user_data.length; i++) {
            first_org = user_data[0].id;
          }
          this.getUserslist(first_org, this.state.pageSize, this.state.activePage);
        } else {
          this.getNonWebastoUsers();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  getNonWebastoUsers() {
    var url = baseUrl.URLPath + "v1.0/tenant/Nonwebasto";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ userArray: response.userDTOS });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  deleteUser() {
    var role = localStorage.getItem('role');
    this.setState({
      modal1: !this.state.modal1
    });
    var id;
    id = this.state.id;
    this.setState({ id: id });
    var url = "";
      url = PlatformUser.DELETE_USER + this.state.activeArray;
    
    fetch(url,
      {
        method: 'DELETE', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization"),
        }
      }
    )
      .then(res => res.json())
      .then(resp => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        else if (resp.status == 404) {
          return resp.json();
        }
        else if (resp.status == 500) {
          alert("internal server error")
        } else if (resp.status == 400) {
          alert("Bad request")
        } else {
          if(localStorage.getItem('userId') == this.state.activeArray){
            localStorage.setItem('Authorization','');
            this.props.history.push('/');
            window.location.reload();
          }
          this.setState({
            modal: !this.state.modal
          });
        }
      })
      .catch(error => alert('Error:' + error));
  }
  toggleFreeWallboxes(id){
    this.setState({
      openFreeWallboxes: !this.state.openFreeWallboxes,
      userid: id
    })
  }
  removeSelectedItem(item, index) {
    const allItems = [...this.state.selectedWallboxes];
    allItems.splice(index, 1);
    const deletedWallboxIndex = this.state.availableWallboxes.findIndex(el => el === item);
    const deletedWallboxes = [...this.state.deletedWallboxes];
    if (deletedWallboxIndex > -1) {
      deletedWallboxes.push(item);
    }
    this.setState({
      ...this.state,
        selectedWallboxes: allItems,
        deletedWallboxes
    })
  }
  isItemSelected(item) {
    const retVal = this.state.selectedWallboxes.find(el => el === item) ? true : false;
    return retVal;
  }
  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedWallboxes];
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    if (event.target.checked) {
      if (selectedItemIndex < 0) {
        allItems.push(item);
      }
    } else {
      const deletedWallboxIndex = this.state.availableWallboxes.findIndex(el => el === item);
      const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item) > -1;
      if (deletedWallboxIndex > -1 && !isAlreadyDeleted) {
        deletedWallboxes.push(item);
      }
      allItems.splice(selectedItemIndex, 1);
    }
    this.setState({
      ...this.state,
        selectedWallboxes: allItems,
        deletedWallboxes
    });
  }
  updateSelectedWallboxes() {
    var url = baseUrl.WebPath + "chargers/clouduser/" + this.state.userid + "/freechargers";

    let selectedWallboxes = this.state.selectedWallboxes;

    fetch(url, {
      method: "PUT",
      body: JSON.stringify([...selectedWallboxes]),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.message == "success") {
          this.toggleFreeWallboxes();
          this.toggleSuccess();
        }
      })
      .catch((error) => {
        console.log(error, "update all selected wallboxes api failed")
      })
  }
  toggleSuccess(){
    this.setState({
      updateWallboxes: !this.state.updateWallboxes
    })
  }
  getAllWallboxes(pageSize, pageNo) {
    var url = baseUrl.WebPath + "chargers/clouduser/allchargers?pageSize=" + pageSize +"&pageNo=" + pageNo;
debugger
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
    .then((resp) => {
      if (resp.status == 401) {
        this.togglesession();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    })
    .then((response) => {
      if (response != undefined) {
        this.setState({ availableWallboxes: response.chargers, noOfRecords1: response.count.count })
      }
    })
    .catch((error) => {
      console.log(error, "get all free wallboxes api failed")
    })
  }
  getAllSelectedWallboxes(id){
    var url = baseUrl.WebPath + "chargers/clouduser/" + id + "/freechargers";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        this.setState({
          selectedWallboxes: response
        })
      })
      .catch((error) => {
        console.log(error, "update all selected wallboxes api failed")
      })
  }
  handleKeyDown(ref, e) {
    var serial_no = e.target.value;

    $("#nochargeexists").hide();

    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();
    }

    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
    var sno = serial_no.slice(0, serial_no.length - 1);

    this.getSearchInventoryList(sno);

    if (key == "Backspace" || key == "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {
        this.state.saveSelectedCpid = sno;
        this.getAllWallboxes(this.state.pageSize, this.state.activePage)
      }
    }

    if (e.keyCode == 13) {
      $("#selectcharge").hide();
      this.FindCpName(ref, e);
      $("#rbt_id").hide();
    }

    if ((e.keyCode == 86 && ctrl)) {
      $("#selectcharge").hide();
      this.getSearchInventoryList(serial_no)
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9 -]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  render() {
    const ref = React.createRef();

    if (localStorage.getItem("role") == roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
      this.state.actiondisplay = "display:block";
    }
    else {
      this.state.actiondisplay = "display:block";
    }

    let userTenant = localStorage.getItem("tenant");
    const user = this.state.user;
    let selectedId = "";

    const organisationDTOS = this.state.dataArray.map((dataArray, index) => {
      if (userTenant === dataArray.identifier) {
        selectedId = dataArray.id
      }
      return (
        <option value={dataArray.id} selected={selectedId == dataArray.id} >{dataArray.company}</option>
      )
    })

    const userDTOS = this.state.userArray.map((userArray, index) => {

      // logic for getting rfids by comma saparated
      var dictRfids = userArray.rfids;
      var getRfids = [];

      if (dictRfids != undefined) {
        for (var i = 0; i < dictRfids.length; i++) {
          getRfids.push(dictRfids[i]["rfidTag"]);
        }
      }

      return (
        <tr>
          <td className="text-center">
            <Form.Check
              custom
              type="checkbox"
              id={`custom-${userArray.id}`}
              label=""
              onChange={this.handleChange.bind(this, `custom-${userArray.id}`, userArray.id)}
              key={userArray.id}
              className="checkbox"
            />
          </td>
          <td>{userArray.id}</td>
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.email}</td>}
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.firstName}</td>}
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.lastName}</td>}
          <td>{userArray.role}</td>
          <td>{(userArray.status)? 'Active':'Inactive'}</td>
          {hasPermission(user, ['Users_U']) && <td style={{ display: this.state.actiondisplay }}> <Link to={{
            pathname: '/editUser',
            state: userArray.id,
          }}><i class="fas fa-pencil-alt   pr-2 action-icon edit_usericon" title="Edit"></i></Link></td>}
          <td style={{textAlign:"center"}}> 
            {
              (userArray.role == roles.WEBASTO_CLOUD_USER)? <Link to={{ pathname: '/cloudWallboxList' }} onClick={() => {
                localStorage.setItem("selectedUserId", userArray.id);
                localStorage.setItem("isCloudUsersPage", true);
                localStorage.setItem("selectedUserEmail", userArray.email);
              }}>View</Link>: <div > - </div>
            }
            </td>
          <td>
            {
              (userArray.role == roles.WEBASTO_CLOUD_USER) ? <span className='edit_link' onClick={() => {
                this.toggleFreeWallboxes(userArray.id);
                this.getAllWallboxes(10, 1);
                this.getAllSelectedWallboxes(userArray.id);
              }}>Edit</span> : <div > - </div>
            }
          </td>
        </tr>
      )
    })
    return (
      <>
        <main class="content-div" >
          <p>Users
            <div className="breadcrumb_div">
              Settings &gt; <span className="breadcrumb_page"> Users</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            <div className="row">
              <div className="col-md-12 ">
                <div className="menubar pull-left">
                  {hasPermission(user, ['Users_W']) && <button onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>Add</span>
                  </button>}
                  {hasPermission(user, ['Users_AD']) && <button onClick={this.activeUsers.bind(this, true)} >
                    <i className="fas fa-toggle-on">
                    </i>
                    <span>Active</span>
                  </button>}
                  {hasPermission(user, ['Users_AD']) && <button onClick={this.activeUsers.bind(this, false)} >
                    <i className="fas fa-toggle-off">
                    </i>
                    <span>Deactive</span>
                  </button>}
                  {hasPermission(user, ['Users_D']) && <button onClick={this.toggleConfirmation}>
                    <i className="fas fa-trash">
                    </i>
                    <span>Delete</span>
                  </button>}
                </div>
                <button class="btn_primary pull-right mt-0  mr-0" type="button" onClick={this.goLoginHistory} >
                  View Login History
                </button>
              </div>
            </div>
            <div className="row  mt-3">
              <MDBCol md="3" className="mt-2" >
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="emailId" onKeyDown={this.keyPress} value={this.state.fields["emailId"]} style={{ width: "60%" }} onChange={this.changeHandler.bind(this, "emailId")} class="form-control input-searchBox pad-2" placeholder="Email" name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail} >Find</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                </div>
              </MDBCol>
              <div className=" col-md-3  pull-right mt-0">
                <select className="browser-default custom-select select_height " id="organizationid" onChange={this.onChangeOrg.bind(this)}>
                  {organisationDTOS}
                </select>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3 mt-2">
                <div className="pull-right">
                  <label className="sub-text pull-left" >Show entries</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* {/ end page menu bar  /}
          
          {/ table start /} */}
            <div className="row mt-2 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="text-center"></th>
                      <th>ID</th>
                      {hasPermission(user, ['UserDetail_R']) && <th>Email</th>}
                      {hasPermission(user, ['UserDetail_R']) && <th>First Name</th>}
                      {hasPermission(user, ['UserDetail_R']) && <th>Last Name</th>}
                      {/* <th>Mobile No</th> */}
                      <th>Role</th>
                      <th>Status</th>
                      {hasPermission(user, ['Users_U']) && <th style={{ display: this.state.actiondisplay }}>Action</th>}
                      <th>Wallboxes</th>
                      <th>Free User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDTOS}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* {/ End table  /} */}
            </div>
          </div>
          {/* Loader */}
          <div className="loader_img" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} style={{ width: "138px" }} alt="" />
          </div>

          {/* User De - Activate Success Popup */}
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>Success</MDBModalHeader>
            <MDBModalBody>
              User deactivated successfully.
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.tagOk1}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          {/* User Activation Success Popup */}
          <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>Success</MDBModalHeader>
            <MDBModalBody>
              User activated successfully.
            </MDBModalBody>
            <MDBModalFooter>
            <button type='button' className='btn_primary' onClick={this.tagOk2}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          {/* User Deletion Popup */}
          <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleConfirmation}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.toggleConfirmation}>{this.state.deleteText}</button>
              {/* <MDBBtn color={this.state.deletecolor} onClick={this.toggleConfirmation}>{this.state.deleteText}</MDBBtn> */}
              <button type='button' className={`btn_primary ${this.state.deleteButton}`} onClick={this.deleteUser}>YES</button>
              {/* <MDBBtn color="primary" className={} onClick={this.deleteUser}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* User Deletion Success Popup */}
          <MDBModal isOpen={this.state.modal} backdrop="static" data-backdrop="static" data-keyboard="false" size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
            <MDBModalBody>
              User deleted successfully.
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.closeOkPopup}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
            <MDBModalBody>
              Please login again.
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.sessiontagOk}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.openFreeWallboxes} size="lg" className="model_top">
            <MDBModalHeader>Free Wallboxes</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12">
                  {
                    this.state.selectedWallboxes.map((el, index) =>
                      <span key={index} className="chargepoint_span">
                        {el} <i class="fa fa-times" onClick={() => this.removeSelectedItem(el, index)}></i>
                      </span>
                    )
                  }
                </MDBCol>
              </MDBRow>
              <MDBRow>
              <div className="col-md-6 pull-right mt-2">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    id="rbt_id"
                    minLength={3}
                    placeholder="Choose a Serial Number..."
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.chargepointIDChange.bind(this)}
                    ref={ref}
                  />
                  <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)}>Find</button>
                  </div>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="selectcharge"><span style={{ color: "red" }}>{this.state.errors["selectChargepointId"]}</span></span>
                </div>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3 mt-2">
                <div className="pull-right">
                  <label className="sub-text pull-left" >Show entries</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries1" onChange={this.showEntriesChange1}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
                <MDBCol md="12" className="chargepoint_listdiv" >
                  <ul>
                    {
                      this.state.availableWallboxes?.map((el, index) =>
                        <li key={index}>
                          <Form.Check
                            custom
                            type="checkbox"
                            id={`${el}_${index}`}
                            label={el}
                            checked={this.isItemSelected(el)}
                            onChange={(event) => this.updateCheckboxSelection(event, el)}
                          />
                        </li>
                      )
                    }
                  </ul>
                </MDBCol>
                <div className="col-md-12 mt-2 mb-4 text-right">
                  <Pagination
                    hideDisabled
                    activePage={this.state.activePage1}
                    itemsCountPerPage={this.state.pageSize1}
                    totalItemsCount={this.state.noOfRecords1}
                    onChange={this.handlePageChange1.bind(this)}
                  />
                </div>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.toggleFreeWallboxes}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.updateSelectedWallboxes}>Update</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.updateWallboxes} size="md" className="model_top">
            <MDBModalHeader>Success</MDBModalHeader>
            <MDBModalBody>
              Free Wallboxes Updated Successfully
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.toggleSuccess}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default userManagementController;