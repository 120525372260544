import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBInputGroup,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Form,Table,Pagination,Check } from 'react-bootstrap'
import * as baseUrl from './commons/constants';
import TableToExcel from 'react-html-table-to-excel';
import {Link} from 'react-router-dom';

class importInventory extends React.Component {  

    constructor (props){
        super(props);
        this.state = { // variables
            data:{},
            sucess:{},
            errors:{},
            docFile:"",
            downloadLink:"",
            model:"false",
            model1:"false",
            modelTile:"",
            modelBody:"",
            selectedFile:"",
            page_title:"Import Inventory"
        }
        this.handleSubmit = this.handleSubmit.bind(this) 
        this.startDownload = this.startDownload.bind(this)
        this.handleChange = this.handleChange.bind(this)
      }


  // functions call when click on download button for downloading status report
  startDownload(){

    this.setState({ //close popup
      modal: !this.state.modal
    });

    var errors={};
    var Url1 = baseUrl.APIPath  + "inventory/bulkupload/"+localStorage.getItem("userId")
    // POST:URL:http://localhost:8090/web/inventory/bulkupload
    var data = new FormData();
    data.append("file",this.state.docFile);
       
        fetch(Url1,{
          method: "POST",
          body: data,
          headers: {
            // "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
          }).then(resp =>{
                  debugger
                  if(resp.status==200){
                    document.getElementById("loader_image_div").style.display = "block"; // loader images
                    this.setState({
                            downloadLink : resp.downloadLink
                          }) 
                  }
                  if(resp.status==404){
                    return resp.json();
                   }else if(resp.status==500){
                     alert("internal server error")
                   } else if(resp.status==400){
                   errors["choosefile"]="Please choose a file."
                   this.setState({errors: errors});
                   }else{
                  debugger
                      setTimeout(() => {
                        document.getElementById("loader_image_div").style.display = "none";
                        this.setState({
                          modal1: !this.state.modal1,
                        });
                      }, 2000)
                     
                      return resp.json();
                  }
                })
              }
  handleChange(e){
    debugger
    var imagedata = document.querySelector('input[type="file"]').files[0];
    // alert(imagedata);
     this.setState({
        docFile:imagedata
     })
  }

//toggle (show and hide) confirmation popup
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

//toggle (show and hide) success popup 
  toggleSuccess = () =>{
    debugger
    this.setState({
      modal1: !this.state.modal1
    });
  }

  //when click on save button
  handleSubmit(event){
    event.preventDefault()
    this.setState({
      modal: !this.state.modal
    });  
   }


      
    componentDidMount(){
      var url = baseUrl.APIPath  + "inventory/template/download"
      // GET:http://localhost:8090/web/inventory/template/download
      fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
        })
        .then((resp) => {
          return resp.json();
        }) 
       
        .catch((error) => {
            console.log(error, "catch the loop")
        })
    }


    render() {
        return (
   <>
      <main class="content-div">
      <p>Import Inventory
          </p>
        <div className="page-outerdiv">

        {/* <p>Bulk Import</p> */}
        <form
          className='needs-validation'
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBCol sm="12" >
            <table  id="table-to-xlsx" className="excel_visi">
              <thead>
                <th>
                  Serial No
                </th>
                <th>
                PUK
                </th>
                <th>Version</th>
                
              </thead>
              <tbody>
                <tr>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                </tr>
              </tbody>
            </table>

           

            {/* </tableToExcel> */}
          </MDBCol>
          <MDBRow className="mb-4 mt-3" >
          <MDBCol sm="12" >
            <div class="md-form input-group mb-0" > 
            <a href ={baseUrl.APIPath  +"inventory/template/download"}>
            <div class="input-group-prepend" className="fa fa-download download_input"></div>
           </a>
           
              <div class="input-group-append">
                <span class="input-group-text md-addon">Download Template</span>
              </div>
            </div>
          </MDBCol>
          <MDBCol sm="6"  className="mt-5" >

         
          <input type="file" accept=".xls,.xlsx" name="file" id="exampleFile"  onChange={this.handleChange.bind(this)}/>
          <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["choosefile"]}</span></span> 
         
        </MDBCol>
        <MDBCol sm="3"  className="">
         <MDBBtn color="primary accent-2" type="submit" className="mt-5" style={{color:"#fff"}} onClick={this.handleSubmit} >
              Upload
        </MDBBtn>
         
        </MDBCol>
        </MDBRow >
       
    </form>
      </div>
      </main>

      <div className="loader_img" id="loader_image_div" style={{display:"none"}}>  
        <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} alt="logo" style={{width: "138px"}}   />
      </div>

          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>Confirmation</MDBModalHeader>
            <MDBModalBody>
              Are you sure, do you want to start import ?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.toggle} >Cancel</MDBBtn>
              <MDBBtn color="primary" onClick={this.startDownload} >Start</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal1} toggle={this.toggleSuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleSuccess}>Success</MDBModalHeader>
            <MDBModalBody>
                Import Inventory completed succesfully. Please select download button to downlaod the results.
            </MDBModalBody>
            <MDBModalFooter>
            {/* GET:http://localhost:8090/web/inventory/download?userId=&lt;UserId&gt; */}
            <MDBBtn color="primary" onClick={this.toggleSuccess}><a style={{ color:'#fff'}} href={baseUrl.APIPath+ "inventory/download?userId="+localStorage.getItem("userId") } >Download</a> 
            </MDBBtn>
              <MDBBtn color="primary" onClick={this.toggleSuccess} >Ok</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          </>
      )
    }      

}

export default importInventory;