import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBInput, Alert } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import * as baseUrl from '../../commons/constants';

export default class ConvenienceFee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page_title: 'Settings',
            dataArray: [],
            modal: false,
            modalLogout: false,
            fields: {
                saveValue: "",
                saveName:""
            },
            loginModal: false,
            editModal: false,
            saveId: '',
            message:'',
            colour:'',
            sucess: false,
            errors:{}
        }
        this.numberHandler = this.numberHandler.bind(this);
        this.CloseLogOutArchive = this.CloseLogOutArchive.bind(this);
        this.toggleLogout = this.toggleLogout.bind(this);
        this.onShowAlert = this.onShowAlert.bind(this);
        this.CloseArchive = this.CloseArchive.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.closeLogin = this.closeLogin.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getWallBox = this.getWallBox.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.closeSuccess = this.closeSuccess.bind(this);
    }
      CloseLogOutArchive = () => {
        this.setState({
          modalLogout: !this.state.modalLogout
        });
      }
      toggleLogout() {
        this.setState({
          modalLogout: !this.state.modalLogout,
          editModal: !this.state.editModal
        });
      }
      closeSuccess(){
        this.setState({
          sucess: !this.state.sucess
        })
      }
      onShowAlert() {
        this.setState({ sucess: true });
      }
      CloseArchive = () => {
        this.setState({
          modal1: !this.state.modal1
        });
      }
      toggleEdit = (id, name, value) => {
        this.state.fields.saveValue = value;
        this.state.fields.saveName = name;
        this.setState({
          editModal: !this.state.editModal,
          saveId: id
        });
      }
      //This is for making user should enter only numbers
      numberHandler(field, e) {
        e.preventDefault();
        let fields = this.state.fields;
        if (e.target.validity.valid) {
          fields[field] = e.target.value;
          this.setState({ fields: fields });
        } else if (e.target.value == "") {
          fields[field] = e.target.value;
          this.setState({ fields: fields });
        }
      }
      closeLogin() {
        this.props.history.push('/');
        window.location.reload();
      }
      toggle = (boolean) => {
        this.setState({
            loginModal: boolean
        });
      }
      async getWallBox() {
        var url = baseUrl.LoginPath + "/web/settings";
    
        await fetch(url, {
          method: "get",
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization")
          }
        })
          .then((resp) => {
            if (resp.status == 401) {
              this.toggle(true);
            }
            if (resp.status == 200) {
              return resp.json();
            }
          })
          .then((response) => {
            if (response != undefined) {
              this.setState({ dataArray: response });
            }
          })
          .catch((error) => {
            console.log(error, "catch the loop")
          })
    
      }
      componentDidMount() {
        document.getElementById("loader_image_div").style.display = "block";
        this.getWallBox();
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
        }, 100);
      }
      updateValue() {
        this.setState({
          modalLogout: !this.state.modalLogout
        });
        var value = this.state.fields.saveValue;
        var getId = this.state.saveId;
        var name = this.state.fields.saveName;
        let errors = {};
        var url = baseUrl.LoginPath + "/web/settings";
        let data = { "id": getId, "settingName": name, "settingValue": value };
        if(value>=0 && value<=100){
            fetch(url, {
                method: 'PUT', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                  "content-type": "application/json",
                  "Authorization": localStorage.getItem("Authorization")
                }
              }).then((resp) => {
                if (resp.status == 200) {
                  this.setState({ editModal: false })
                  this.setState({ message: "Setting Value updated sucessfully", colour: 'success' })
                  this.onShowAlert();
                  this.getWallBox();
                }
                else if (resp.status == 400) {
                }
                else if (resp.status == 403) {
                }
                else if (resp.status == 401) {
                  this.toggle(true);
                } else if (resp.status == 404) {
                  return resp.json();
                } else {
                  return resp.json();
                }
              }).then((response) => {
                this.setState({ errors: errors })
              })
              .catch(error => alert('Error:' + error));
        }else{
            this.setState({ editModal: false })
            this.setState({ message: "Setting Value must be between 0 to 100", colour: 'danger' })
            this.onShowAlert();
        }
        this.setState({ errors: errors });
      }
    render() {
        return (
            <>
                <main class="content-div">
                    <p>Convenience Fee
                        <div className="breadcrumb_div">Settings &gt; <span className="breadcrumb_page">Convenience Fee</span></div>
                    </p>
                    <div className="page-outerdiv">
                        {/* table start */}
                        <div className="row mt-2 ">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Value (%)</th>
                                            <th>Edit Value</th>
                                        </tr>
                                    </thead>
                                    <tbody className="vertical-center" >
                                        {this.state.dataArray.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.settingName}</td>
                                                    <td>{item.settingValue}</td>
                                                    <td>
                                                        <i class="fas fa-pencil-alt pr-2 action-icon edit_usericon" title="Edit Setting Value" onClick={this.toggleEdit.bind(this, item.id, item.settingName, item.settingValue)}></i>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {
                                            (this.state.dataArray.length == 0) ?
                                                <tr>
                                                    <td colSpan="3" style={{ textAlign: "center" }}>No Data Available</td>
                                                </tr> : null
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {/* End table  */}
                        </div>
                    </div>
                </main>
                <div className="loader_img" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} style={{ width: "138px" }} alt="" />
                </div>
                <MDBModal isOpen={this.state.loginModal} size="md" className="model_top">
                    <MDBModalHeader>Session has expired</MDBModalHeader>
                    <MDBModalBody>
                        Please login again
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.closeLogin}>OK</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.sucess} size="md" className="model_top">
                    <MDBModalHeader>Success</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.message}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.closeSuccess}>OK</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                {/* confirmation message popup for edit  */}
                <MDBModal isOpen={this.state.editModal} toggle={this.toggleEdit} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleEdit}>Convenience Fee</MDBModalHeader>
                    <MDBModalBody className="needs-validation">
                        <MDBRow>
                            <MDBCol md="6">
                                <MDBInput
                                    name="saveValue"
                                    value={this.state.fields["saveValue"]}
                                    min="0"
                                    pattern="[0-9.]*"
                                    id="saveValue"
                                    label="Value (%)"
                                    autoComplete="off"
                                    onInput={this.numberHandler.bind(this, "saveValue")}
                                >
                                </MDBInput>
                                <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["valueError"]}</span></span>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.toggleEdit}>Close</MDBBtn>
                        <MDBBtn color="primary" onClick={this.toggleLogout} >Update</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                {/* confirmation message for update */}
                <MDBModal isOpen={this.state.modalLogout} toggle={this.toggleLogout} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleLogout}>Confirmation</MDBModalHeader>
                    <MDBModalBody>
                        Do you want to update?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.CloseLogOutArchive.bind(this)}>Close</MDBBtn>
                        <MDBBtn color="primary" onClick={this.updateValue}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        );
    }
}