import React from 'react';
import { MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import * as baseUrl from '../../commons/constants';
import { Line, Bar } from "react-chartjs-2";
import Dialog from 'react-bootstrap-dialog';
import { Typeahead } from 'react-bootstrap-typeahead'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory();

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      options: [],
      users: [],
      serial_No: "",
      intial_data: [],
      multiple: false,
      yearmonthName: [],
      allUserMonth: [],
      allUserValue: [],
      alluserStep: {
        min: 0,
        suggestedMax: 10
      },
      userStep: {
        min: 0,
        suggestedMax: 10
      },
      userData: [],
      userValue: [],
      dataBar: [],
      allUser: [],
      dataLine: '',
      page_title: "Statistics",
      products: [],
      modal: false,
      userSelection: "disabled",
      errors:{}
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.getMonthList = this.getMonthList.bind(this);
    this.UserhandleChange = this.UserhandleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.getusers = this.getusers.bind(this);
  }

  getusers(sno){
    document.getElementById("organization").options[0].selected = true;
      document.getElementById("organization").value = "";
      this.setState({
        userSelection: ""
      })

    var url = baseUrl.URLPath + "web/statistics/chargers/" + sno + "/users";
    var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }
    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          document.getElementById("organization").options[0].selected = true;
          this.state.dataBar = {
            labels: this.state.yearmonthName,
            datasets: [
              {
                label: "kWh of Charged energy usage",
                data: [0, 0, 0, 0, 0, 0, 0],
                backgroundColor: "rgba(255, 134,159,0.4)",
                borderWidth: 2,
                borderColor: "rgba(255, 134, 159, 1)"
              }
            ]
          }
          this.setState({
            users: response,
          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  FindCpName(ref, event){debugger
    let sno = ref.current.inputNode.value;
    if(this.state.intial_data.includes(sno)){
      let errors = this.state.errors;
      errors['nowallboxes'] = ''
      this.setState({
        errors: errors
      }, ()=>{
        this.getusers(sno);
        this.GetAllUser(sno)
      })
    } else {
      let errors = this.state.errors;
      errors['nowallboxes'] = 'Invalid Serial No'
      this.setState({
        errors: errors
      })
    }
  }

  ClearChargepoint(ref, event){
    document.getElementById("organization").options[0].selected = true;
      document.getElementById("organization").value = "";
    let errors = {};
    errors["chargePointNotExists"] = "";
    this.setState({
      errors: errors,
      userSelection: "disabled"
    });
    
    ref.current.clear();
  }

  tagOk() {
    history.push('/');
    window.location.reload();
  }

  async toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  selectSerialNumber(e) {

    // alert(e.target.value);
  }

  handleInputChange(input, e) {

  }

  ifNoUserselected = () => {
    document.getElementById("organization").options[0].selected = true;
    this.state.dataBar = {
      labels: this.state.yearmonthName,
      datasets: [
        {
          label: "kWh of Charged energy usage",
          data: [0, 0, 0, 0, 0, 0, 0],
          backgroundColor: "rgba(255, 134,159,0.4)",
          borderWidth: 2,
          borderColor: "rgba(255, 134, 159, 1)"
        }
      ]
    }
    this.setState({ usersData: [] });
  }

  UserhandleChange(e) {
    var user_month = [];
    var user_value = [];
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var option = optionElement.getAttribute('data-id');
    var url = baseUrl.URLPath + "web/statistics/users/" + option;
    var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }

    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }

      })
      .then((response) => {
        if (response != undefined) {
          for (var i = 0; i < response.length; i++) {
            user_month.push(response[i]["month"])
            user_value.push(response[i]["value"])
          }

          this.state.userValue = user_value;
          var max_val = Math.max(...user_value);

          if (max_val < 10) {
            this.state.userStep = {
              min: 0,
              suggestedMax: 10,

            }
          } else {
            this.state.userStep = {
              min: 0,
              suggestedMax: max_val,
            }
          }

          this.state.dataBar = {
            labels: this.state.yearmonthName,
            datasets: [
              {
                label: "kWh of Charged energy usage",
                data: user_value.reverse(),
                backgroundColor: "rgba(255, 134,159,0.4)",
                borderWidth: 2,
                borderColor: "rgba(255, 134, 159, 1)"
              }
            ]
          }
          this.setState({ usersData: response })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getMonthList() {
    var monthName = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec");
    var d = new Date();
    d.setDate(1);
    var i = 0;
    for (i = 0; i <= 11; i++) {
      this.state.yearmonthName.push(monthName[d.getMonth()] + ' ' + d.getFullYear().toString().substr(-2));
      d.setMonth(d.getMonth() - 1);
    }

    this.state.yearmonthName = this.state.yearmonthName.reverse();
    // this.state.userValue.reverse();

    this.state.dataBar = {
      labels: this.state.yearmonthName,
      datasets: [
        {
          label: "kWh of Charged energy usage",
          data: this.state.userValue.reverse(),
          backgroundColor: "rgba(255, 134,159,0.4)",
          borderWidth: 2,
          borderColor: "rgba(255, 134, 159, 1)",

        }
      ]
    }

    this.state.dataLine = {
      labels: this.state.yearmonthName,
      datasets: [
        {
          label: "EVSE accumulated charged energy",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "rgb(205, 130, 158)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.allUserValue.reverse()
        }
      ]
    }
  }

  handleChange(selectedOptions) {
    this.setState({
      serial_No: selectedOptions
    })
    this.GetAllUser(selectedOptions)
    if (selectedOptions == "") {
      document.getElementById("organization").options[0].selected = true;
      document.getElementById("organization").value = "";
      this.setState({
        userSelection: "disabled"
      })
    }
    if (selectedOptions != "") {
      document.getElementById("organization").options[0].selected = true;
      document.getElementById("organization").value = "";
      this.setState({
        userSelection: ""
      })
    }
    if (selectedOptions.length > 0) {

      var url = baseUrl.URLPath + "web/statistics/chargers/" + selectedOptions + "/users";
      var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }

      fetch(url, {
        method: "GET",
        headers: header
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.toggle();
            localStorage.clear();

          }
          if (resp.status == 200) {
            return resp.json();
          }

        })
        .then((response) => {
          if (response != undefined) {
            document.getElementById("organization").options[0].selected = true;
            this.state.dataBar = {
              labels: this.state.yearmonthName,
              datasets: [
                {
                  label: "kWh of Charged energy usage",
                  data: [0, 0, 0, 0, 0, 0, 0],
                  backgroundColor: "rgba(255, 134,159,0.4)",
                  borderWidth: 2,
                  borderColor: "rgba(255, 134, 159, 1)"
                }
              ]
            }
            this.setState({
              users: response,
            })
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }

  }

  GetAllUser(serial_number) {
    var url = baseUrl.URLPath + "web/statistics/charger/" + serial_number + "/statistics";
    var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }
    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }

      })
      .then((response) => {
        var allUserData = [];
        if (response != undefined) {
          var allUser_month = [];
          var allUser_value = [];

          for (var i = 0; i < response.length; i++) {
            allUser_month.push(response[i]["month"])
            allUser_value.push(response[i]["value"])
          }

          var max_val = Math.max(...allUser_value);

          if (max_val < 10) {
            this.state.alluserStep = {
              min: 0,
              suggestedMax: 10,
            }
          } else {
            var new_obj = {
              min: 0,
              suggestedMax: 10,
            }
            this.state.alluserStep = new_obj;


          }

          this.state.dataLine = {
            labels: this.state.yearmonthName,
            datasets: [
              {
                label: "EVSE accumulated charged energy",
                fill: true,
                lineTension: 0.3,
                backgroundColor: "rgba(225, 204,230, .3)",
                borderColor: "rgb(205, 130, 158)",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "rgb(205, 130,1 58)",
                pointBackgroundColor: "rgb(255, 255, 255)",
                pointBorderWidth: 10,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgb(0, 0, 0)",
                pointHoverBorderColor: "rgba(220, 220, 220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: allUser_value.reverse()
              }
            ]
          }
          this.setState({
            allUserMonth: allUser_month,
            allUserValue: allUser_value
          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  handleKeyDown(e) {
    var serial_no = e.target.value;
    var key = e.key;
    var list = this.state.intial_data;
    if (key == "Backspace" || key == "Delete") {
      var serial_no = e.target.value;
      if (serial_no.length <= 1) {
        list = [];
        this.setState({
          options: list,
          items: list,
        });

        this.state.dataLine = {
          labels: this.state.yearmonthName,
          datasets: [
            {
              label: "EVSE accumulated charged energy",
              fill: true,
              lineTension: 0.3,
              backgroundColor: "rgba(225, 204,230, .3)",
              borderColor: "rgb(205, 130, 158)",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgb(205, 130,1 58)",
              pointBackgroundColor: "rgb(255, 255, 255)",
              pointBorderWidth: 10,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgb(0, 0, 0)",
              pointHoverBorderColor: "rgba(220, 220, 220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          ]
        }

      }
    } else {
      if (serial_no.length >= 0) {
        this.setState({
          options: list
        });
      }
    }
  }

  componentDidMount() {
    this.getMonthList();
    var url = baseUrl.URLPath + "web/statistics/chargers";
    var header = { 'content-type': 'application/json', 'Authorization': localStorage.getItem("Authorization") }
    fetch(url, {
      method: "GET",
      headers: header
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        var data = [];
        for (var i = 0; i < response.length; i++) {
          data.push(response[i]["serial_number"])
        }
        this.setState({ intial_data: data })
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  render() {
    var option_new = "";
    if (this.state.serial_No != "") {
      option_new = <option value="01" data-id={'freecharge?serialNo=' + this.state.serial_No} >Free Charge</option>
    }

    const usersoption = this.state.users.map((item, index) => {
      return (
        <option value={index} data-id={item.id}> {item.email} </option>
      )
    });
    const ref = React.createRef();
    return (
      <>
        <main class="content-div">
          <p>Statistics
            <div className="breadcrumb_div">Report &gt; <span className="breadcrumb_page">Statistics</span></div>
          </p>
          <div className="page-outerdiv">
            <div className="row mt-1" >
              <div className="col-md-4  pull-right mt-2">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    id="rbt_id"
                    minLength={3}
                    placeholder="Choose a Serial Number..."
                    onKeyDown={this.handleKeyDown.bind(this)}
                    onChange={this.handleChange.bind(this)}
                    ref={ref}
                  />
                  <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)}>Find</button>
                  </div>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors['nowallboxes']}</span></span>
                </div>
              </div>
              <div className="col-md-3  pull-right mt-2">
                <select disabled={this.state.userSelection} className="browser-default custom-select select_height" id="organization" onChange={this.UserhandleChange.bind(this)}>
                  <option value="">Select User</option>
                  {option_new}
                  {usersoption}
                </select>
              </div>
            </div>

            {/* end page menu bar  */}
            <MDBRow className="mt-2">
              <div className="col-lg-6 col-md-6 mb-4 mt-2" id="graph3">
                <div className="card card-cascade narrower pb-3 content" >
                  <div class="image-overlay"></div>
                  <div className="view view-cascade gradient-card-header green">
                    <h5 className="mb-0">
                      All Users
                    </h5>
                  </div>
                  <div className="card-body card-body-cascade text-center chartdiv ">
                    <div className="home_disablechart">
                      <Line data={this.state.dataLine} options={{
                        scales: {
                          yAxes: [{
                            scaleLabel: {
                              display: true,
                              labelString: 'Kw / h'
                            },
                            ticks: this.state.alluserStep
                          }],

                          xAxes: [{
                            scaleLabel: {
                              display: true,
                              labelString: 'Month'
                            }
                          }]
                        }
                      }} />
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-4 mt-2" id="graph4">


                <div className="card card-cascade narrower pb-3 content">


                  <div className="view view-cascade gradient-card-header warning-color">
                    <h5 className="mb-0">User Monthly Report</h5>
                  </div>

                  <div className="card-body card-body-cascade text-center">

                    <Bar data={this.state.dataBar} options={{
                      scales: {
                        yAxes: [{
                          scaleLabel: {
                            display: true,
                            labelString: 'Kw / h'
                          },
                          ticks: this.state.userStep
                        }],

                        xAxes: [{
                          scaleLabel: {
                            display: true,
                            labelString: 'Month'
                          }
                        }]
                      }
                    }} />

                  </div>
                </div>
              </div>
            </MDBRow>
          </div>
          <Dialog ref={(el) => { this.dialog = el }} size="md" />
        </main>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default Reports;