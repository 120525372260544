import React from 'react';
import Datetime from 'react-datetime';
import {Link} from 'react-router-dom';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter, MDBCardText} from 'mdbreact';
import  $ from "jquery";
import * as baseUrl from './commons/constants';
// import GeoLocation from './googleMap';
import { Alert } from 'reactstrap';
import { createBrowserHistory } from 'history'


const history = createBrowserHistory();

class addBuild extends React.Component{  
    constructor (props){
        super(props);
        this.state = {
          // prerequisiteValidation : false,
          model:false,
          dataArray:[],
          orgsArray:[],
          targetData:[],
          buildTypes:[],
          taregtTypeValue:"",
          buildTypeId:"",
          prerequisite:"",
          version:"",
          releaseNotes:'',
          prerequisites:[],
          productid:'',
          fields: { 
          version:"",
        
          releaseNotes:"",
          targetIds:""
        },
          sucess:{},
          errors: {},
          activeItem: "1",
          model:"false" ,
          page_title:"Add Build",
          file:"",
          sucess:false,
          colour:'',
           message:'',
          allOrSelected:false,
          typeofRadio:false
        }
        this.onShowAlert=this.onShowAlert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.targetTypechange = this.targetTypechange.bind(this);
        this.backToList=this.backToList.bind(this);
        this.getProducts=this.getProducts.bind(this);
        this.clearAllRadios=this.clearAllRadios.bind(this);
        this.changeHandlerTarget=this.changeHandlerTarget.bind(this);
        this.toggle = this.toggle.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.getPrerequisite = this.getPrerequisite.bind(this);
        this.prerequisiteChange = this.prerequisiteChange.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        
}
async getOrganizations(){
  var url = baseUrl.URLPath + "admin/v1.0/account/all";
  await fetch(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": localStorage.getItem("Authorization")
    }
  })
    .then((resp) => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    })
    .then((response) => {
      if (response != undefined) {
        this.setState({orgsArray : response.organisationDTOS })
      } else {
      }
    })
    .catch((error) => {
      console.log(error, "catch the loop")
    })
}
tagOk(){
  this.props.history.push('/');
  window.location.reload();
}
async toggle(){
  debugger;
  this.setState({
  modal: !this.state.modal
  });
}

onShowAlert(){
  debugger
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });
}

productChange(){
  this.getPrerequisite();
  var productId=document.getElementById("productId").value;
  this.state.productid=productId;
  this.setState({productid: productId});
  this.getBuildTypes();
}

fileChange(e){
    debugger
    var imagedata = document.getElementById("buildFile").files[0];
    var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
    simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);
    simple_file_upload.addEventListener('focus', function(){
    simple_file_upload.classList.add('focus');
    });
    simple_file_upload.addEventListener('blur', function(){
    simple_file_upload.classList.remove('focus');
    });
    this.setState({
    file:imagedata
    })
  
}
releaseChange(){
  debugger
  var imagedata = document.getElementById("releaseNotes").files[0];
  var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
  simple_file1.nextElementSibling.setAttribute('data-file', simple_file1.files[0].name);
  simple_file1.addEventListener('focus', function(){
    simple_file1.classList.add('focus');
  });
  simple_file1.addEventListener('blur', function(){
    simple_file1.classList.remove('focus');
  });
  this.setState({
  releaseNotes:imagedata
  })
}
buildTypeChange(){
 
  var id=document.getElementById("buildTypes").value;
  this.setState({buildTypeId:id});
  this.getPrerequisite();

}
prerequisiteChange(){
  debugger;
  var id=document.getElementById("prerequisites").value; 
  this.setState({prerequisite:id})
}

getBuildTypes(){
  debugger
  var productId=document.getElementById("productId").value;
  var url = baseUrl.URLPath + "web/dashboard/buildtypes/"+this.state.productid+"?pageNo=0&pageSize=0";
  fetch(url,{
    method: "GET",
    headers: {
      "content-type":"application/json",
      "Authorization":localStorage.getItem("Authorization")
    }
    })
    .then((resp) => {
      debugger;
      if(resp.status==401){
        this.toggle();
       // alert("Access token is expired, please login again")
       localStorage.clear();
    
     }
     if(resp.status==200){
       return resp.json();
     }    }) 
    .then((response) =>{
      if(response!=undefined){
            
        // alert(JSON.stringify(response));
       this.setState({ buildTypes: response})
         }
    // alert("data"+JSON.stringify(this.state.data));
    // this.setState({ total_charge_point:response.data.chargePointCount })

    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
 
}

getProducts(e){
  var orgId=document.getElementById("organization").value;
  this.setState({orgId:orgId})
  debugger;
  if(orgId!=''){
    var url = baseUrl.URLPath + "web/dashboard/"+orgId+"/products?pageNo=0&pageSize=0";
    fetch(url,{
      method: "GET",
      headers: {
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
      })
      .then((resp) => {
        debugger;
        if(resp.status==401){
          this.toggle();
         // alert("Access token is expired, please login again")
         localStorage.clear();
      
       }
       if(resp.status==200){
         return resp.json();
       }      }) 
      .then((response) =>{
        if(response!=undefined){
            
          // alert(JSON.stringify(response));
         this.setState({ dataArray: response.productList});
           } 
      // alert("data"+JSON.stringify(this.state.data));
      // this.setState({ total_charge_point:response.data.chargePointCount })

      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
    }else{
      this.setState({ dataArray:[]});
    }
  }
  getPrerequisite(){
    debugger;
    var product_id=document.getElementById("productId").value;
    var build_type_id=document.getElementById("buildTypes").value;
    var orgId=document.getElementById("organization").value;
    this.setState({orgId:orgId})
    var url = baseUrl.URLPath + "web/dashboard/"+orgId+"/prerequisite?product_id="+product_id+"&build_type_id="+build_type_id;
    // alert(url);
    
//localhost:8090/web/dashboard/1/prerequisite?product_id=2&build_type_id=4

     fetch(url,{
      method: "GET",
      headers: {
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
    })
    .then((resp) => {
      debugger;
      if(resp.status==401){
        this.toggle();
       // alert("Access token is expired, please login again")
       localStorage.clear();
    
     }
     if(resp.status==200){
       return resp.json();
     }    }) 
    .then((response) =>{
      debugger
      if(response!=undefined){
            
        //  alert(JSON.stringify(response));
       this.setState({ prerequisites:response})
         } 
    // alert("data"+JSON.stringify(this.state.data));

    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })

  }

 //This is for making user should enter only numbers
 numberHandler(field, e){ 
    let fields = this.state.fields;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }

  rollOutchange(field, e){
   if(field=="other"){
       document.getElementById("target_typeid").style.display = 'block';
       document.getElementById("target-type-textarea").style.display = 'none';
       document.getElementById("rolloutid").style.display = 'none';
       this.clearAllRadios('targetType')
       this.setState({
        typeofRadio:false
      })
    }
  }

  targetTypechange(field, e){

    if(field=="ALL"){
      document.getElementById("target-type-textarea").style.display = 'none';
      document.getElementById("target_typeid").style.display = 'none';
      document.getElementById("rolloutid").style.display = 'block';
      this.clearAllRadios('rollOut')
      document.getElementById("all").checked = true;
      this.setState({
        typeofRadio:true
      })
    }else{
      this.setState({taregtTypeValue:""})
      document.getElementById("target-type-textarea").style.display = 'block';
    }
  }

  clearAllRadios(name) {
     var radList = document.getElementsByName(name);
     for (var i = 0; i < radList.length; i++) {
       if(radList[i].checked) radList[i].checked = false;
    }
  }
  
  organizationChange(){
    this.getProducts(this);
  }
  componentDidMount(){
    this.getOrganizations();
    document.getElementById("all").checked = true;
    debugger;
    this.setState({productId:this.props.location.state})
    var url = baseUrl.URLPath + "web/dashboard/targettypes";
     fetch(url,{
      method: "GET",
      headers: {
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
    })
    .then((resp) => {
      debugger;
      if(resp.status==401){
        this.toggle();
       // alert("Access token is expired, please login again")
       localStorage.clear();
    
     }
     if(resp.status==200){
       return resp.json();
     }    }) 
    .then((response) =>{
      debugger
      if(response!=undefined){
            
        // alert(JSON.stringify(response));
       this.setState({ targetData:response})
         } 
    // alert("data"+JSON.stringify(this.state.data));
    // this.setState({ total_charge_point:response.data.chargePointCount })

    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })

     

        // var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
        // simple_file1.addEventListener('change', function(){
        //   this.nextElementSibling.setAttribute('data-file', this.files[0].name);
        // });
        // simple_file1.addEventListener('focus', function(){
        //   simple_file1.classList.add('focus');
        // });
        // simple_file1.addEventListener('blur', function(){
        //   simple_file1.classList.remove('focus');
        // });


        }
  backToList(){
    debugger;
    this.props.history.state=this.props.location.state;
    window.history.state.state=this.props.location.state;
    this.props.history.push('/builds')
  }
 handleSubmit() {
  var text = this.state.taregtTypeValue;
  this.state.fields.targetIds = text;
  var orgId=document.getElementById("organization").value;
  var productId=document.getElementById("productId").value;
  var buildTypeId=this.state.buildTypeId;
  // var rollout = document.getElementById("target_typeid").value;
  // alert(rollout);

var errors={};
debugger;
if(this.state.file=="" || this.state.file==undefined){
  errors["nofileError"]="Please select firmware file."
}
if(orgId==""){
  errors["orgError"]="Please select the organization."
}
if(productId==""){
  errors["prodError"]="Please select the product."
}
if(buildTypeId==""){
  errors["buildTypeError"]="Please select the build-type."
}
if(this.state.fields.version==""){
  errors["versionError"]="Please enter the version."
}
if(this.state.releaseNotes=="" || this.state.releaseNotes==undefined){
  errors["releaseNoteError"]="Please select release notes."
}
// if(this.state.prerequisite >= this.state.fields.version){
//   this.state.prerequisiteValidation = true;
//  errors["prerequisite"]="Prerequisite should not be greater or equal of version."

// }
if(this.state.file!=''&&this.state.file!= undefined &&orgId!=""&&productId!=""&&buildTypeId!=""&&this.state.fields.version!=""&&this.state.releaseNotes!=""&&this.state.releaseNotes!=undefined){
  var url = baseUrl.URLPath + "web/dashboard/"+orgId+"/ota/"+productId;
  this.setState({errors:errors})             
  var data = new FormData();   
  data.append("file",this.state.file);
  data.append("version_name",this.state.fields.version);
  data.append("build_type_id",buildTypeId);
  data.append("target_type_id",0);
  data.append("release_note",this.state.releaseNotes); 
  data.append("prerequisite",this.state.prerequisite);  
  data.append("target_ids",this.state.fields.targetIds);  
    fetch(url,{
      method: "POST",
      body:data,
      headers: {
        "Authorization":localStorage.getItem("Authorization")
      }
      })
      .then((resp) => {
        
        if(resp.status==201){
          
          this.setState({message:"New build is added sucessfully.",colour:'success'})
          this.onShowAlert();
        }
        if(resp.status==404){
          
         return resp.json();
 } 
        else{
          return resp.json();
        }
      }) 
      .then((response) =>{
        
        var fileExten = response[0].recommendation
        if(fileExten == "Please select .gbl file to upload."){
          errors["fileExten"]= fileExten
          this.setState({errors:errors})

        }
        else if(fileExten == "Please select .zip file to upload."){
          errors["zipExten"]= fileExten
          this.setState({errors:errors})
        }
        else if(fileExten == "Prerequsite should not be greater or equal of version."){
          errors["prerequisite"]= fileExten
          this.setState({errors:errors})
        }
        else if(fileExten == "Build already exists with this version, build type and product."){
         this.setState({message:fileExten,colour:'danger'})
         this.onShowAlert();

        }
        else if(fileExten == "Access Denied"){
          this.setState({message:fileExten,colour:'danger'})
          this.onShowAlert();
        }

        // this.onShowAlert();
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
}else{
  this.setState({errors:errors})

}
}

  changeHandler(field, e){ 

  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields});
  }
  changeHandlerTarget(e){ 
    this.setState({taregtTypeValue:e.target.value});
    }
  
  countryChange(){
    var x = document.getElementById("country").value;
    let fields = this.state.fields;
    fields["country"] = x; 
    this.setState({fields});
  }

  render() {
    const products =this.state.dataArray.map((dataArray, index) => {
  return(
 <option value={dataArray.id}>{dataArray.product_name}</option>
)
    })
    const buildTypes =this.state.buildTypes.map((data, index) => {
      return(
      <option value={data.id}>{data.name}</option>
      )
          })
          const prerequisites =this.state.prerequisites.map((data, index) => {
            return(
            <option value={data.id}>{data.version}</option>
            )
                })
         

          const targetdata =this.state.targetData.map((data, index) => {
 
            

            return(
              <MDBInput
              type="radio"
                name="targetType"
                id={`target_type-${data.id}`}
                label={data.name}
                autoComplete="off"
                value={data.name}
                className="pull-left"
                onChange={this.targetTypechange.bind(this,data.name)}
              >
                
              </MDBInput>
            )
                })

                const organisationDTOS = this.state.orgsArray.map((dataArray, index) => {
                  return (
                    <option value={dataArray.id}>{dataArray.company}</option>
                  )
                });

  return (
    <>
    <main class="content-div">
        <p> Builds
          <div className="breadcrumb_div">
            FOTA &gt; <Link to="./builds">Builds</Link > &gt; <span className="breadcrumb_page">Add</span>
          </div>
        </p>

    {/* <p className="breadcrumb_div">Maintenance > <Link to="/chargePoint" > <span className="">Overview</span></Link> >  Add</p> */}
      <form
          className='needs-validation'
          
          noValidate
        >
      <div className="page-outerdiv">


        {/* page title */}
        {/* <p> Add Build </p> */}
        {/* end page title */}
        <div>
        <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
          
          <div class="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{display:"none"}} >
             
              {/* <button type="button" class="close" data-dismiss="alert">&times;</button> */}
          </div>
             <span class=" form-title">Details:</span> 
          </div>
          <MDBCol md="4">
             <div className="md-form">
              <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.organizationChange.bind(this)}>
                <option value="">Select Organization</option>
                {organisationDTOS}
                </select>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["orgError"]}</span></span> 
                </div>
                </MDBCol>
                <MDBCol md="4">
                <div className="md-form">
              <select className="browser-default custom-select select_height mb_8" id="productId" onChange={this.productChange.bind(this)}>
                <option value="">Select Product</option>
                {products}
                </select>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["prodError"]}</span></span> 
                </div>
                </MDBCol>
                
           <MDBCol md="4">
                <MDBInput
                  value={this.state.fields["version"]}
                  // onChange={this.changeHandler}
                  onChange={this.numberHandler.bind(this, "version")}
                  type="text"
                  label="Version"
                  autoComplete="off"
                  // pattern="[0-9.]*"
                  
                >
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["versionError"]}</span></span> 

                   
          </MDBInput>

              </MDBCol>
              <MDBCol md="4">
              <div className="md-form mt-2" >

              <select className="browser-default custom-select select_height mb_8" id="buildTypes" onChange={this.buildTypeChange.bind(this)}>
                <option value="">Select Build Type</option>
                {buildTypes}
                </select>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["buildTypeError"]}</span></span> 
             </div>
                </MDBCol>
                <MDBCol md="4">
                {/* <MDBInput
                  value={this.state.fields["prerequisite"]}
                  // onChange={this.changeHandler}
                  onChange={this.changeHandler.bind(this, "prerequisite")}
                  type="text"
                  label="Prerequisite"
                  autoComplete="off"
                  className="mt-2"
                  
                >
          </MDBInput> */}
           <div className="md-form mt-2" >

          <select className="browser-default custom-select select_height mb_8" id="prerequisites" onChange={this.prerequisiteChange.bind(this)}>
           <option value="">Select Prerequisite</option>
           {prerequisites}
         </select>
         <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["prerequisite"]}</span></span> 

        </div>

              </MDBCol>
          <MDBCol md="4">
            <label className="mt-1" >
              <input type="file" class="simple_file_upload"  id="buildFile" onChange={this.fileChange.bind(this)} />
              <span data-file="No file choosen">Choose Firmware file</span>

            </label>
              {/* <MDBInput
                className="mt-0.5"
               id="buildFile"
                type="file"
                onChange={this.fileChange.bind(this)}
              > */}

                {/* <label for="buildFile">Upload Firmware</label> */}
             <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["nofileError"]}</span></span> 
             <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["fileExten"]}</span></span> 
             <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["zipExten"]}</span></span> 

           {/* </MDBInput> */}
          </MDBCol>
        </div>

        <div className="row">
          <MDBCol md="4" >
          <label className="mt-1">
              <input type="file" class="simple_upload1"  id="releaseNotes" onChange={this.releaseChange.bind(this)} />
              <span data-file="No file choosen">Choose Release Notes</span>
            </label>
          <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["releaseNoteError"]}</span></span> 
          {/* <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["fileExten"]}</span></span>  */}

                  
               
              </MDBCol>

 {/* rollout */}
              <MDBCol md="6" className="in-flex mt-4" id="rolloutid" style={{"display":"block"}}>
              <span className="pull-left pl-1">RollOut:</span> 
                <MDBInput
                  type="radio"
                  name="rollOut"
                  label="All"
                  id="all"
                  onChange={this.rollOutchange.bind(this,"all")}
                  value="0"
                  className="pull-left"
                >
                </MDBInput>
      
             
                <MDBInput
                  type="radio"
                  name="rollOut"
                  id="selected"
                  label="Selected"
                  autoComplete="off"
                  disabled
                  value="other"
                  className="pull-left disable_radio"
                  onChange={this.rollOutchange.bind(this,"other")}
                >
                  
                </MDBInput>
              </MDBCol>
 {/* Target Type */}
              <MDBCol md="8" className="in-flex mt-4" id="target_typeid"  style={{"display":"none"}}>
              <span className="pull-left pl-1">Target Type:</span> 
               
         {targetdata}
             
                
              </MDBCol>

              <MDBCol md="12" className="mt-3"> 
                  <textarea  
                  value={this.state.taregtTypeValue}
                  onChange={this.changeHandlerTarget.bind(this)}
                  class="form-control rounded-0" 
                  placeholder="Please use comma separated" 
                  id="target-type-textarea" 
                  rows="3" 
                  style={{"display":"none"}}>
                  </textarea>
              </MDBCol>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["chargepointidempty"]}</span></span> 

              {/* <div className="col-md-12 mb-3">
          
             
            
          </div>
           */}

  {/* <MDBCol md="12" className="mt-4">
    <GeoLocation google={this.props.google} center={{lat:18.520,lng:73.8567}} height='300px' zoom={15} />
  </MDBCol>  */}
     <MDBCol md="12" className="text-right mt-3">
      <MDBBtn color='primary' type='button'  onClick={this.handleSubmit}>
        Save
      </MDBBtn>
      <MDBBtn color='primary' onClick={this.backToList} >
        <span style={{color:"#fff"}}> Back </span>   
      </MDBBtn>
    </MDBCol> 
</div>


    </div>
      
    </form>
    </main>
    <MDBModal isOpen={this.state.modal} size="md" className="model_top">
      <MDBModalHeader>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
    </>
  );
}
}
 export default addBuild;


