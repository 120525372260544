import React from 'react';
import { Alert } from 'reactstrap';
import * as baseUrl from './commons/constants';
import '../App.css';

class updatePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { oldpwd: this.props.location.state.syspassword, newpwd: '' },
            sucess: {},
            update_Password: false,
            errors: {},
            message: "",
            sucess: false,
            colour: 'sucess',
            activeItem: "1",
            Tokenexpired: false,
            Tokenmsg: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
    }

    componentWillMount() {
        
    }

    handleSubmit(event) {
        event.preventDefault();
        let errors = {};
        let valid = true;
        var newpwd = this.state.fields['newpwd'];
        if (newpwd == '') {
            errors["pwdempty"] = "Password cannot be empty";
            valid = false;
        } else if(newpwd.trim().length < 8 || 
                  /\d/.test(newpwd) == false ||
                  /[A-Z]/.test(newpwd) == false ||
                  /[a-z]/.test(newpwd) == false ||
                  /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(newpwd) == false
                ){
            errors["entervalidpwd"] = "Password should be a minimum of 8 characters at least 1 Uppercase Alphabet, 1 Lowercase Alphabet, 1 Number, and 1 Special Character";
            valid = false;
        } else if(newpwd.trim().length > 20){
            errors["entervalidpwd"] = "Password should not exceed maximum 20 characters";
            valid = false;
        }

        this.setState({ errors: errors });

        if (valid == true) {
            let url = baseUrl.URLPath + "v1.0/user/updatepassword";
            var newpwd;
            let data = { "oldPassword": this.state.fields.oldpwd, "newPassword": this.state.fields.newpwd };
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/json",
                    "Authorization": localStorage.getItem("Authorization"),
                }
            }).then(resp => {
                if (resp.status == 404) {
                    errors["pwdempty"] = "Sorry the reset password link has expired.";
                    this.setState({ errors: errors });
                } else {
                    return resp.json();
                }
            }).then((response) => {
                if (response != undefined) {
                    if (response.status == "FAILURE") {
                        errors["pwdempty"] = response.errorMessage
                        this.setState({
                            errors: errors
                        });
                    } else {
                        this.setState({ message: "Your password has been updated successfully!", colour: 'success' })
                        this.onShowAlert();
                        this.setState({
                            update_Password: true
                        })
                        localStorage.clear();
                    }
                }
            }).catch((error) => {
                console.log(error, "catch the loop")
            })
        }
    }

    handleChange(field, e) {
        let value = e.target.value;
        let fields = this.state.fields;
        if(value?.trim().length <= 20){
            fields[field] = value.trim();
            this.setState({ fields });
        }
    }

    onShowAlert() {
        this.setState({ sucess: true }, () => {
            window.setTimeout(() => {
                this.setState({ sucess: false })
            }, 50000000)
        });
    }

    handleLogin() {
        this.props.history.push('/');
    }

    render() {
        if (this.state.Tokenexpired == true) {
            return (
                <h6 className="text-center pt-3">{this.state.Tokenmsg}</h6>
            );
        } else if (this.state.update_Password == true) {
            return (
                <div className="login-page">
                    <header>
                        {/* Navbar */}
                        {/* Intro Section */}
                        <section className="view intro-2">
                            <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                                            {/* Form with header */}
                                            <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                                                <div className="card-body">
                                                    {/* Header */}
                                                    <div className="col-md-12 login_icon">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} style={{ width: "138px" }} alt="" />
                                                    </div>
                                                    <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Update Password</p>
                                                    {/* Body */}
                                                    <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                                                    <div className="text-center" >
                                                        <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onClick={this.handleLogin}>Sign In</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Form with header */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Intro Section */}
                    </header>
                </div>
            )
        } else {
            return (
                <div className="login-page">
                    <header>
                        {/* Navbar */}
                        {/* Intro Section */}
                        <section className="view intro-2">
                            <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                                            {/* Form with header */}
                                            <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                                                <div className="card-body">
                                                    {/* Header */}
                                                    <div className="col-md-12 login_icon">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} style={{ width: "138px" }} alt="" />
                                                    </div>
                                                    <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Update Password</p>
                                                    {/* Body */}
                                                    <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2">{this.state.message}</Alert>
                                                    <div className="md-form">
                                                        <i className="fas fa-lock prefix white-text" />
                                                        <input type="password" disabled id="orangeForm-pass" className="form-control" value={this.state.fields["oldpwd"]} />
                                                        <label htmlFor="orangeForm-pass" class="active">Old-Password</label>
                                                    </div>
                                                    <div className="md-form">
                                                        <i className="fas fa-lock prefix white-text" />
                                                        <input type="password" id="orangeForm-name" className="form-control active" onChange={this.handleChange.bind(this, "newpwd")} value={this.state.fields["newpwd"]} />
                                                        <label htmlFor="orangeForm-name">New-Password</label>
                                                        <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["pwdempty"]}</span></p></span>
                                                        <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span>
                                                    </div>
                                                    <div className="text-center">
                                                        <button type='submit' className="btn login-btn btn-lg waves-effect waves-light mb-4" onClick={this.handleSubmit}>submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Form with header */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Intro Section */}
                    </header>
                </div>
            )
        }
    }
};

export default updatePassword;