import React from 'react';
import { Alert } from 'reactstrap';
import * as baseUrl from './commons/constants';
import '../App.css';

class resetpassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { newpwd: '', confirmpwd: '', token: '' },
            sucess: {},
            reset_Password: false,
            errors: {},
            message: "",
            sucess: false,
            colour: 'sucess',
            activeItem: "1",
            Tokenexpired: false,
            Tokenmsg: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
    }

    async validateUrl() {
        var location = window.location.href;
        var allurl = location.split("/");
        var n = allurl.length;
        this.state.fields["token"] = allurl[n - 2];
        var url1 = baseUrl.LoginPath + "/system/authentication/verifytoken/" + allurl[n - 2];
        fetch(url1, {
            method: 'GET', // or 'PUT'
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            return resp.json();
        }).then(response => {
            if(Array.isArray(response) && response[0]?.recommendation!=""){
                this.setState({ Tokenexpired: true, Tokenmsg: response[0]?.recommendation });
            } else {
                this.setState({ Tokenexpired: false });
            }
        }
        ).catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    componentWillMount() {
        this.validateUrl();
    }

    handleSubmit(event) {
        event.preventDefault();

        let errors = {};
        var newpwd = this.state.fields['newpwd'];
        var confirmpwd = this.state.fields['confirmpwd'];
        let valid = true;

        if (newpwd == '' && confirmpwd == '') {
            errors["pwdempty"] = "Password  cannot be empty";
            errors["confirmpassword"] = "confirm password cannot be empty";
            valid = false;
        } else if (newpwd == '') {
            errors["pwdempty"] = "Password cannot be empty";
            valid = false;
        } else if (confirmpwd == '') {
            errors["confirmpassword"] = "Confirm password cannot be empty";
            valid = false;
        } else if (confirmpwd != newpwd) {
            errors["confirmpassword"] = "New password and confirm password doesn't match";
            valid = false;
        } else if(newpwd.trim().length < 8 ||
        /\d/.test(newpwd) == false ||
        /[A-Z]/.test(newpwd) == false ||
        /[a-z]/.test(newpwd) == false ||
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(newpwd) == false
        ){
            errors["confirmpassword"] = "Password should be a minimum of 8 characters at least 1 Uppercase Alphabet, 1 Lowercase Alphabet, 1 Number, and 1 Special Character";
            valid = false;
        } else if(newpwd.trim().length > 20){
            errors["confirmpassword"] = "Password should not exceed maximum 20 characters";
            valid = false;
        }

        this.setState({ errors: errors });

        if (valid == true) {
            let url = baseUrl.LoginPath + "/system/authentication/resetpassword";
            var newpwd;
            
            let data = { "password": this.state.fields.newpwd, "confirmPassword": this.state.fields.confirmpwd, "token": this.state.fields.token };
            fetch(url, {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/json",
                }
            }).then(resp => {
                if (resp.status == 400) {
                    return resp.json()
                } else if (resp.status == 404) {
                    errors["pwdempty"] = "Sorry the reset password link has expired.";
                    this.setState({ errors: errors });
                } else {
                    if (resp.status == 200) {
                        this.setState({ message: "Your password has been changed successfully!", colour: 'success' })
                        this.onShowAlert();
                        this.setState({
                            reset_Password: true
                        })
                        return resp.json();
                    }
                }
            }).then((response) => {
                if (response.status == 400) {
                    errors["pwdempty"] = response.message;
                    this.setState({ errors: errors });
                } else if (response.message == "Success") {
                    this.setState({ message: "Your password has been changed successfully!", colour: 'success' })
                    this.onShowAlert();
                    this.setState({
                        reset_Password: true
                    })
                }
            }).catch((error) => {
                console.log(error, "catch the loop")
            })
        }
    }

    handleChange(field, e) {
        let value = e.target.value;
        let fields = this.state.fields;
        if(value?.trim().length <= 20){
            fields[field] = value.trim();
            this.setState({ fields });
        }
    }

    onShowAlert() {
        this.setState({ sucess: true }, () => {
            window.setTimeout(() => {
                this.setState({ sucess: false })
            }, 50000000)
        });
    }
    handleLogin() {
        this.props.history.push('/');
    }
    render() {
        if (this.state.Tokenexpired == true) {
            return (
                <h6 className="text-center pt-3">{this.state.Tokenmsg}</h6>
            );
        } else if (this.state.reset_Password == true) {
            return (
                <div className="login-page">
                    <header>
                        {/* Navbar */}
                        {/* Intro Section */}
                        <section className="view intro-2">
                            <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                                            {/* Form with header */}
                                            <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                                                <div className="card-body">
                                                    {/* Header */}
                                                    <div className="col-md-12 login_icon">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} style={{ width: "138px" }} alt="" />
                                                    </div>
                                                    <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Reset Password</p>
                                                    {/* Body */}
                                                    <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                                                    <div className="text-center" >
                                                        <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onClick={this.handleLogin}>Sign In</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Form with header */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Intro Section */}
                    </header>
                </div>
            )
        } else {
            return (
                <div className="login-page">
                    <header>
                        {/* Navbar */}
                        {/* Intro Section */}
                        <section className="view intro-2">
                            <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                                            {/* Form with header */}
                                            <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                                                <div className="card-body">
                                                    {/* Header */}
                                                    <div className="col-md-12 login_icon">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} style={{ width: "138px" }} alt="" />
                                                    </div>
                                                    <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Reset Password</p>

                                                    {/* Body */}
                                                    <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                                                    <div className="md-form">
                                                        <i className="fas fa-lock prefix white-text" />
                                                        <input type="password" id="orangeForm-name" className="form-control active" onChange={this.handleChange.bind(this, "newpwd")} value={this.state.fields["newpwd"]} />
                                                        <label htmlFor="orangeForm-name">New-Password</label>
                                                        <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["pwdempty"]}</span></p></span>
                                                        <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span>
                                                    </div>
                                                    <div className="md-form">
                                                        <i className="fas fa-lock prefix white-text" />
                                                        <input type="password" id="orangeForm-pass" className="form-control" onChange={this.handleChange.bind(this, "confirmpwd")} value={this.state.fields["confirmpwd"]} />
                                                        <label htmlFor="orangeForm-pass">Confirm-Password</label>
                                                        <span className="error_msg1 w3-animate-top"><p> <span style={{ color: "red" }} >{this.state.errors["confirmpassword"]}</span></p></span>
                                                    </div>
                                                    <div className="text-center">
                                                        <button type='submit' className="btn login-btn btn-lg waves-effect waves-light mb-4" onClick={this.handleSubmit}>submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Form with header */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Intro Section */}
                    </header>
                </div>
            )
        }
    }
};

export default resetpassword;